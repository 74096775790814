import * as React from "react"
import { Router } from "@reach/router"
import PublicGiftSelector from "../social-supermarket/pages/gift-selection/PublicGiftSelector"

const ShareableGiftSelectionTemplate = () => {
  return (
    <div>
      <Router>
        <PublicGiftSelector path="gift-selection/:orderKey" />
      </Router>
    </div>
  )
}

export default ShareableGiftSelectionTemplate
